import React, { lazy } from "react"
import { useTranslation } from "react-i18next"
import Account from "../pages/Settings/Account/Account"
import Management from "../pages/Settings/Management/Management"
import { RouterProps } from "./routerProps"

// Import lazy-loaded components
const HomePage = lazy(() => import("../pages/PageHome"))
const DashboardPage = lazy(() => import("../pages/PageDashboard"))
const OrdersPage = lazy(() => import("../pages/PageOrders"))
const ProductsPage = lazy(() => import("../pages/PageProducts"))
const CustomersPage = lazy(() => import("../pages/PageCustomers"))
const AnalyticsPage = lazy(() => import("../pages/PageAnalytics"))
const SettingsPage = lazy(() => import("../pages/Settings/PageSettings"))

// Higher-Order Component to inject routes
const withRoutes = (Component: React.ComponentType<RouterProps>) => {
	return (props: Omit<RouterProps, "routes">) => {
		const { t, i18n } = useTranslation()

		const routes = [
			{ path: "/", element: <HomePage />, index: true },
			{ path: "home", element: <HomePage /> },
			{ path: "dashboard", element: <DashboardPage /> },
			{ path: "orders", element: <OrdersPage /> },
			{ path: "products", element: <ProductsPage /> },
			{ path: "customers", element: <CustomersPage /> },
			{ path: "analytics", element: <AnalyticsPage /> },
			{
				path: "settings",
				element: <SettingsPage />,
				children: [
					{
						path: "account",
						label: t("settings.account.title"), // Use translation for the label
						element: <Account />,
						role: ["admin", "dev"],
						index: true,
					},
					{
						path: "appearance",
						label: t("settings.appearance.title"),
						element: <div>appearance component</div>,
						role: ["admin", "dev"],
					},
					{
						path: "notifications",
						label: t("settings.notifications.title"),
						element: <div>notifications component</div>,
						role: ["admin", "dev"],
					},
					{
						path: "management",
						label: t("settings.management.title"),
						element: <Management />,
						role: ["admin", "dev"],
					},
				],
			},
		]

		console.log("i18n language:", i18n.language) // For debugging purposes

		// Pass routes as props to the wrapped component
		return <Component {...props} routes={routes} />
	}
}

export default withRoutes
