import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom"
import LoginPage from "../pages/PageLogin"
import ResetForm from "../pages/PageResetPassword"
import NewPassword from "../pages/NewPassword/NewPassword"
import MainLayout from "../layouts/mainLayout"
import withRoutes from "./Routes"
import { Toaster } from "@/components/ui/toaster"
import { ProtectedRoute } from "@/routes/ProtectedRoute"
import { lazy, Suspense } from "react"
import { RouterProps } from "./routerProps"

// Import lazy-loaded components
const Page404 = lazy(() => import("../pages/NotFound/PageNotFound"))

const router = ({ routes }: RouterProps) => {
	console.log("Routes:", routes) // For debugging purposes
	const router = createBrowserRouter([
		{
			path: "/",
			element: (
				<ProtectedRoute>
					<MainLayout>
						<Suspense fallback={<div>Loading...</div>}>
							<Outlet />
							<Toaster />
						</Suspense>
					</MainLayout>
				</ProtectedRoute>
			),
			children: routes, // ici on injecte les routes
		},
		{
			path: "login",
			element: <LoginPage />,
		},
		{
			path: "reset_password",
			element: <ResetForm />,
		},
		{
			path: "new_password",
			element: <NewPassword />,
		},
		{
			path: "404",
			element: <Page404 />,
		},
		{
			path: "*",
			element: <Navigate to="/404" replace />,
		},
	])

	return <RouterProvider router={router} />
}

export const Router = withRoutes(router)
